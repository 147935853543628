<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import { required } from "vuelidate/lib/validators";
import BasicInfo from "@/components/loyalty/campaign-basic-info" 
import Rules from "@/components/loyalty/campaign-rules"

import {
	
} from "@/state/helpers";

/**
 * Edit campaign Component
 */
export default {
	page: {
		title: "Campaign",
		meta: [
			{
				name: "description",
				content: appConfig.description,
			},
		],
	},
	components: {
		Layout,
		PageHeader,
		BasicInfo,
		Rules
	},
	data() {
		return {
			title: "",
			items: [],
			isLoading: false,
			fullPage: true,
			campaign:{},
			submitted:false,
		}
	},
	validations: {
		campaign: {
			name: {
				required,
			},
			description: {
				required,
			},
			startDate: {
				required,
			},
		},
	},
	mounted() {
		const campaignId = this.$route.query.id;
		
		if(campaignId){
			this.title = 'Editar Campaña - 10 puntos por compra en APP';
			//load loyalty campaing
			this.campaign = {
          _id: '1',
          name: '10 puntos por compra en APP',
          startDate: new Date(),
          endDate: null, 
          createdAt: new Date(),
          status: 1,
					rules:[
						{name: 'Clientes premium', status:1, conditions:{children:[]}, prizes:[]},
						{name: 'Monto mayor a $1000', status:1, conditions:{children:[]}, prizes:[]}
					]
        }
		}
		else{
      this.title = 'Nueva Campaña'
			//new campaing
		}
	},
	created() {
		
	},
	methods: {
		onCancelCampaignClicked(){
			this.$router.push({
            path: `/loyalty-campaigns`
            }
        ).catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error)
        });
		},
		onConfirmCreateOrEditCampaignClicked(){

		}
	},
	
};
</script>

<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<div class="checkout-tabs">
			<b-tabs
				pills
				vertical
				nav-class="p-0"
				nav-wrapper-class="col-xl-2 col-sm-3">
				<b-tab active>
					<template v-slot:title>
						<i class="bx bxs-briefcase-alt d-block check-nav-icon mt-4 mb-2"></i>
						<p class="fw-bold mb-4">{{$t('campaigns.new_campaign_basicInfo')}}</p>
					</template>
					<b-card-text>
						<div class="card">
							<div class="card-body">
								<h4 class="card-title">{{$t('campaigns.new_campaign_basicInfo')}}</h4>
								<p class="card-title-desc">{{$t('campaigns.new_campaign_basicInfo_subtitle')}}</p>
								<BasicInfo :campaign="campaign"></BasicInfo>
							</div>
						</div>
					</b-card-text>
				</b-tab>
				<b-tab>
					<template v-slot:title>
						<i class="bx bxs-crown d-block check-nav-icon mt-4 mb-2"></i>
						<p class="fw-bold mb-4">Reglas</p>
					</template>
					<b-card-text>
						<div class="card">
							<div class="card-body">
								<h4 class="card-title">Reglas</h4>
								<p class="card-title-desc mb-0">Configura las reglas para esta campaña ordenadas por prioridad.</p>
								<Rules :rules="campaign.rules"></Rules>
							</div>
						</div>
					</b-card-text>
				</b-tab>
				<b-tab>
					<template v-slot:title>
						<i class="bx bxs-wrench d-block check-nav-icon mt-4 mb-2"></i>
						<p class="fw-bold mb-4">{{$t('campaigns.edit_campaign_settings_title')}}</p>
					</template>
					<div class="card">
						<div class="card-body">
							<h4 class="card-title">{{$t('campaigns.edit_campaign_settings_title')}}</h4>
							<p class="card-title-desc">Configurá los limites para esta campaña</p>
						</div>
					</div>
				</b-tab>
			</b-tabs>
		</div>
		<div class="row mt-4">
			<div class="col-sm-6">
			</div>
			<!-- end col -->
			<div class="col-sm-6">
				<div class="text-end">
					<b-button variant="light" class="w-md mb-3" @click="onCancelCampaignClicked">{{$t('common.cancel')}}</b-button>
					<b-button variant="primary" class="w-md ms-3 mb-3" @click="onConfirmCreateOrEditCampaignClicked">{{$t('common.accept')}}</b-button>
				</div>
			</div>
			<!-- end col -->
		</div>
	</Layout>
</template>

<style scoped>

.edit-picture-button {
	height: 1.5rem !important;
	width: 1.5rem !important;
	top: -5px;
	position: absolute;
	color:#adb5bd
}

.icon-color-grey {
	color:#adb5bd
}

</style>