<script>
import ExpressionBuilder from "@/components/widgets/queryBuilder";
import EmptyList from '@/components/widgets/empty_list'
import Multiselect from "vue-multiselect";
import loyaltyFields from '@/components/loyalty/loyaltyFields'
import {
	propertyMethods,
  projectMethods,
  audienceMethods
} from "@/state/helpers";

/**
 * New Campaign  component
 */

export default {
	components: {
		ExpressionBuilder,
		EmptyList,
    Multiselect,
    loyaltyFields
	},
	data() {
		return {
			submitted:false,
      index:0,
			empty_config:{
        "title" : 'No se encontraron condiciones',
        "subtitle" : 'No se encontraron condiciones, comienza creando tu primera condición para esta regla.',
        "buttonText" : 'Nueva Condición',
        icon: "fa-cubes"
      },
			properties_products:[],
      categories:[],
      audiences: [],
      memberships: [],
      properties_crm: [],
      showFieldsModal: false,
      prizes:[
        {id:'points', label: 'Sumar Puntos'},
        {id:'reward', label: 'Brindar Recompensa'}
      ],
      auxPrize: {id:'points', label: 'Sumar Puntos'},
      rewards:[],
      auxReward: null,
      auxPoints: 100,
      
		};
	},
	methods: {
    ...propertyMethods,
    ...projectMethods,
    ...audienceMethods,

    loadAudiences(){
      let loader = this.$loading.show();
      const params = {
        q : `where[project]=${localStorage.getItem("current_project")}&limit=100`
      }
      this.getAudiences(params).then((audiences)=>{
        this.audiences = audiences.data || [];
      }).catch(()=>{

      }).finally(()=>{
        loader.hide();
      })
    },

		addFixedProperties(){
      this.properties_products.push({
        type : 'product_price',
        name: this.$t("audiences.audience_conditions_fields_interaction_products_with_price"),
        column_name: 'product_price'
      });
			this.properties_products.push({
        type : 'product_category',
        name: this.$t("audiences.audience_conditions_fields_interaction_products_with_category"),
        column_name: 'product_category'
      });
    },
		loadCategories(){
      const params = {
				q : `where[project]=${localStorage.getItem("current_project")}`
			}
			
			this.getItemsCategories(params).then((categories)=>{
				this.categories = categories;
			}).catch(()=>{

			}).finally(()=>{
				
			})
		},

		loadProperties(){
      let loader = this.$loading.show();
      const params = {
        q : `project=${localStorage.getItem("current_project")}`
      }
      this.getProperties(params).then((properties)=>{
        this.properties_products = properties.filter(p=> p.entity == 'product');
        this.properties_crm = properties.filter(p=> p.entity == 'customer');

        this.addFixedProperties();
        
      }).catch(()=>{

      }).finally(()=>{
        loader.hide();
      })
    },
    loadMemberships(){
      this.memberships = [
      {
          _id: '1',
          name: 'Gold',
          members: 102339,
          status: 1,
          points: 1000
        },
        {
          _id: '2',
          name: 'Silver',
          members: 72123,
          status: 1,
          points: 500
        },
        {
          _id: '3',
          name: 'Bronze',
          members: 50120,
          status: 1,
          points: 0
        },
      ]
    },
    onNextClicked(){
			if(this.index == 0){
				this.index = 1;
				return;
			}
		},
		onTabSelected(index){
			this.$emit('indexChanged',index);
		},
		onCreateConditionClicked(){
      
      this.showFieldsModal = true;
    },
    
		formatDate(date) {
      var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

      if (month.length < 2) 
        month = '0' + month;
      if (day.length < 2) 
        day = '0' + day;
      
      return [day, month, year].join('/');
    },
			
    onFieldSelected(id){

      this.rule.conditions.children.push({
          field: id,
          operator: null,
          term: null,
          times:{},
          new: true
        });
        this.showFieldsModal = false;
    },
    onPrizeTypeChanged(){
      this.auxReward = null;
      this.auxPoints = 100;
    }
	},
	computed: {
		
	},
	mounted() {
		this.loadProperties();
		this.loadCategories();
    this.loadAudiences();
    this.loadMemberships();
  },
	props: {
		rule : Object,
		tabIndex: Number,
	},
	emits: ['indexChanged'],
	watch: {
		tabIndex:{
      immediate: true,
      handler(newVal){
        if(newVal){
					this.index = newVal;
        }
      }
    },
		
	}
};
</script>

<template>
	<div class="row">
		<b-tabs v-model="index" justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
			<b-tab v-on:click="onTabSelected(0)">
				<template v-slot:title>
					<span class="d-inline-block d-sm-none">
						Condiciones
					</span>
					<span class="d-none d-sm-inline-block"> <i class="bx bx-task"></i> Condiciones</span>
				</template>
				<label>Configura las condiciones que se deben cumplir para que el cliente reciba los puntos.</label>
          <EmptyList :config="empty_config" v-if="rule.conditions.children?.length == 0" @onButtonClicked="onCreateConditionClicked" class="mt-4"/>
					<ExpressionBuilder :query="rule.conditions" :conditionType="'loyaltyType'" :properties_products="properties_products" :properties_crm="properties_crm" :affinity_Types="[]" :audiences="audiences" v-if="rule.conditions?.children?.length > 0" :categories="categories" :custom_interactions="[]" :contactLists="[]" :memberships="memberships" />
			</b-tab>
			<b-tab v-on:click="onTabSelected(1)">
				<template v-slot:title>
					<span class="d-inline-block d-sm-none">
						<label>Premio</label>
					</span>
					<span  class="d-none d-sm-inline-block"> <i class="bx bx-award"></i> Premio</span>
				</template>
				<label>Configura el premio que recibirá el cliente.</label>

        <div class="row">
          <div class="col-sm-6">
            <multiselect
              :options="prizes" 
              v-model="auxPrize"
              selectLabel=""
              deselectLabel=""
              track-by="id" 
              label="label"
              :multiple="false"
              :allowEmpty="false"
              @input="onPrizeTypeChanged"
            />
          </div>
          <div class="col-sm-6">
            <multiselect
              v-if="auxPrize.id == 'reward'"
              :options="rewards" 
              v-model="auxReward"
              selectLabel=""
              deselectLabel=""
              track-by="id" 
              label="label"
              :multiple="false"
              :allowEmpty="false"
            />

            <b-form-input
              v-model="auxPoints"
              v-if="auxPrize.id == 'points'">
            </b-form-input>
          </div> 
        </div>
      </b-tab>
		</b-tabs>
    <b-modal v-model="showFieldsModal" hide-footer :title="$t('audiences.audience_conditions')">
			<loyaltyFields @onValueClicked="onFieldSelected" :custom_interactions="[]" />
		</b-modal>
	</div>
	
</template>

<style scoped>

.interact{
	cursor: pointer;
}
</style>