<script>

import { required } from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import {} from "@/state/helpers";

export default {
	components: {
		DatePicker,
		Multiselect
	},
	data() {
		return {
			submitted:false,
      startDate:null,
			endDate: null,
			triggers: [
				{ id:"transaction", label: "Cliente realiza una compra"}
			],
			auxTrigger: { id:"transaction", label: "Cliente realiza una compra"}
		};
	},
	validations: {
		campaign: {
			name: {
				required,
			},
      startDate: {
        required
      }
		},
	},
	methods: {
		startDateChanged(){
			this.campaign.startDate= this.startDate;
			
		},
		endDateChanged(){
			this.campaign.endDate= this.endDate;
		},
		onValidateCampaign(){
			this.submitted = true;
			this.$v.$touch();

			if (this.$v.$invalid) {
				return false;
			}

			return true;
		},
		onTriggerOptionChanged(){
			this.campaign.trigger = this.auxTrigger;
		}
  },
	mounted() {
		
	},
	props: {
		campaign : {
			type: Object
		}
	},
  watch: {
    campaign: {
      immediate: true,
      handler(newVal){
        if(newVal){
          this.startDate = newVal.startDate;
          this.endDate = newVal.endDate;
					this.auxTrigger = this.campaign.trigger || this.auxTrigger;
        }
      }  
    }
  }
};
</script>

<template>
	<div>
		<div class="row">
			<div class="col">
				<div class="mb-3">
					<label>Nombre*</label>
					<input
						v-model="campaign.name"
						type="text"
						class="form-control"
						:class="{
							'is-invalid': submitted && $v.campaign.name.$error,
						}"
					/>
					<div
						v-if="submitted && !$v.campaign.name.required"
						class="invalid-feedback">
							El nombre es requerido
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<div class="mb-3">
					<label>Trigger*</label>
					<multiselect
						:options="triggers" 
						v-model="auxTrigger"
						selectLabel=""
						deselectLabel=""
						track-by="id" 
						label="label"
						:multiple="false"
						:allowEmpty="false"
						@input="onTriggerOptionChanged"
						:disabled="campaign._id"
						/>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-6">
				<div class="mb-3">
					<label>Activa Desde*</label>
					<date-picker
						id="campaignStartDate"
						v-model="startDate"
						name="campaignStartDate"
						:class="{
						'is-invalid': submitted && $v.campaign.startDate.$error,}"
						@input="startDateChanged"
					/>
					<div
						v-if="submitted && !$v.campaign.startDate.required"
						class="invalid-feedback">
						{{$t('campaigns.new_campaign_basicInfo_startDate_required')}}
					</div>
				</div>
			</div>
			<div class="col-sm-6">
				<div class="mb-3">
					<label>Activo Hasta</label>
					<date-picker
						id="campaignEndDate"
						v-model="endDate"
						name="campaignEndDate"
						type="date"
						@input="endDateChanged"
					/>
				</div> 
			</div> 
		</div>
  </div>
</template>

<style scoped>

</style>