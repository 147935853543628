<script>
import Draggable from 'vuedraggable'
import Rule from  "@/components/loyalty/campaign-rule"

import {} from "@/state/helpers";

export default {
	components: {
		Draggable,
    Rule
	},
	data() {
		return {
			drag:false,
      tabIndex: 0,
      rule:{},
      showRuleModal: false
		};
	},
	methods: {
		onEditRuleClicked(data){
      this.rule = data;
      this.tabIndex = 0;
      this.showRuleModal = true;
    },
    onRemoveRuleClicked(data){
      // eslint-disable-next-line no-console
      console.log(data);
    },
    onCloneRuleClicked(data){
      // eslint-disable-next-line no-console
      console.log(data);
    },
    onChangeStatusClicked(index){
      const status = this.rules[index].status == 1 ? 0 : 1;
      
      const rule = this.rules[index];
      rule.status = status;
      
      this.$set(this.rules, index, rule);
    },
    onTabIndexChanged(index){
      this.tabIndex = index;
    },
    onNextOrConfirmRuleClicked(){
      if(this.tabIndex == 0){
        this.tabIndex = 1;
      }else{
        this.showRuleModal = false;
      }
    },
    onNewRuleClicked(){
      this.rule = {
        name: `Rule ${this.rules.length + 1}`,
        conditions: {
          type:'and',
          children: []
        },
        prizes:[]
      },
      this.tabIndex = 0;
      this.showRuleModal = true;
    }
  },
	mounted() {
		
	},
	props: {
		rules : {
			type: Array
		}
	},
  
};
</script>

<template>
	<div>
    <div class="row" v-if="rules.length > 0">
      <div class="text-end col">
        <button type="button" v-on:click="onNewRuleClicked" class="btn btn-sm btn-primary mt-0 mb-3" >+ Nueva Regla</button>
      </div>
    </div>
		<Draggable v-model="rules" group="people" @start="drag=true" @end="drag=false" :forceFallback="true">
      <div class="card" v-for="(element,index) in rules" :key="element.id" style="background-color: rgba(85,110,230,.1); margin-bottom: 15px; ">
          <div class="card-body row ">
            <div class="col-sm-8" v-on:click="onEditRuleClicked(element)" style="cursor: pointer;">
              <div class="d-inline-flex align-items-baseline">
                <i class="mdi-drag-vertical mdi align-self-center font-size-24 me-1 text-muted " style="cursor: grab;"></i>
                <div>
                  <h5 class="mb-0 interact">
                    {{element.name}}                      
                  </h5>
                  <p class="small mb-0 interact">{{ element.conditions?.children?.length || 0 }} condiciones</p>
                </div>
              </div>
            </div>
            <div class="col-sm-4 text-end d-flex justify-content-end align-items-baseline" style="margin: auto;">
              <span class="badge font-size-12 me-5"
                :class="{
                'bg-success': `${element.status}` == 1,
                'bg-warning': `${element.status}` == 0}">
                  {{ element.status == 1 ? 'Activa' : 'Inactiva' }}
              </span>
                
              <div class="d-inline-flex align-items-baseline">
                <i class="fas fa-play text-primary me-2 interact" v-on:click="onChangeStatusClicked(index)" v-if="element.status == 0"/>
                <i class="fas fa-pause text-primary me-2 interact" v-on:click="onChangeStatusClicked(index)" v-if="element.status == 1"/>
                
                <b-dropdown
                  class="card-drop"
                  variant="white"
                  right
                  toggle-class="p-0"
                  menu-class="dropdown-menu-end">
                  <template v-slot:button-content>
                  <i class="mdi mdi-dots-horizontal font-size-18"></i>
                  </template>

                  <b-dropdown-item @click="onCloneRuleClicked(element)">
                    <i class="fas fa-clone text-info me-1 interact"></i>
                    {{$t('common.duplicate')}}
                  </b-dropdown-item>
                  <b-dropdown-item @click="onEditRuleClicked(element)">
                    <i class="fas fa-edit text-success me-2 interact"></i>
                    {{$t('common.edit')}}
                  </b-dropdown-item>
                  <b-dropdown-item @click="onRemoveRuleClicked(index)">
                    <i class="fas fa-trash-alt text-danger me-2 interact"></i>
                    {{$t('common.delete')}}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            
          </div>
        </div>
    </Draggable>
    <b-modal
					v-model="showRuleModal"
					id="modal-xl"
					size="xl"
          no-close-on-backdrop
					title-class="font-18"
          @ok.prevent="onNextOrConfirmRuleClicked"  :okTitle="tabIndex == 0 ? $t('common.next') :  $t('common.confirm')"  :cancelTitle="$t('common.cancel')">
          <template #modal-title>
              <input class="form-control" v-model="rule.name" style="font-weight: bold;"/>
          </template>
				<Rule :rule="rule" :tabIndex="tabIndex" @indexChanged="onTabIndexChanged" ></Rule>
			</b-modal>
  </div>
  
</template>

<style scoped>

</style>